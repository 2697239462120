
<template>
  <div class="col-md-7 col-sm-7 mt-10 boxmain" v-if="my_data==null">
    <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                    <Skeleton></Skeleton>
                </h2>

              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-12 col-lg-12 col-xl-12">


                <div class="row" v-for="(v,k) in 7" :key="k">
                  <div class="col-md-5 col-lg-5 col-xl-5">
                    <div class="news-wrapper" style="height:100%">
                      <a href=""
                        title="">
                          <Skeleton height="80%"></Skeleton>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7 col-lg-7 col-xl-7 p-left">
                    <div class="news-description">
                      <h3>
                        <a href=""
                          title="">
                            <Skeleton > </Skeleton>
                        </a>
                      </h3>
                      <h4 class="the-title">
                        <a href=""
                          title="">

                          <Skeleton height="100%"></Skeleton>
                      

                        </a>
                      </h4>
                      <div class="post-link text-left">
                        <span class="entry-date mrr-20">
                          <i class="fa fa-calendar mrr-10 text-primary-color"></i>
                          <Skeleton width="50px"></Skeleton>
                        </span>
                        <span class="entry-date">
                          <i class="fa fa-eye mrr-10 text-primary-color"></i>
                          <Skeleton width="50px"></Skeleton>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              

              </div>
            </div>
          </div>
         
        </SkeletonTheme>
  </div>

   <div class="col-md-7 col-sm-7 mt-10 boxmain" v-else>
          <section class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                    {{$t('general.ablum_title')}}
                </h2>

              </div>
            </div>
          </section>
          <div class="container">
              <div class="row">


                <div class="col-md-6 col-lg-6 col-xl-6 section-content-image-li" v-for="(v,k) in paginationslice(my_data, 10, page)" :key="k">
                  <div class="project-item mrb-30">
                    <a class="project-thumb"
                       :href="$router.resolve({ name:'albumdetail',params:{
                            locale:$i18n.locale,slug:v.slug
                       }}).href" 
                       :title="v.name">
                      <img class="img-full lazy" :alt="v.name" :src="myurl+''+v.picture">
                      <div class="project-details p-3">
                        <h3 class="project-title">
                          {{v.name}}
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>
               


              </div>
              <div class="row">
                <div class="col-xl-12">
                  <nav class="pagination-nav pdt-30" v-if="my_data.length>10">
                    

                    <VuePagination
                    :page-count="Math.floor(my_data.length / 10)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination-list text-center'"
                    :page-class="'page-numbers'"
                  >
                  </VuePagination>
                  </nav>
                </div>
              </div>
          </div>

        </div>


</template>


<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
  import axios from 'axios';
export default {
  
 
  data() {
    return {
      

      myurl:window.my_url,
      my_data:null,
      mycate:null,


      page: 1,

      
    };
  },

  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination
    
  },
  created(){
            axios.get(window.my_api+"api/album/get-albums?language="+this.$i18n.locale,
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
              .then( (res)=>{
                  
                  if(res.status==200){

                       this.my_data=res.data.data;
                                            
                  }else {
                    console.log("ERROR",res);
                  }
                   

                });
          
      
  },
  methods:{
   
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    changePage(e) {
      this.page = e;
    }
  }
}
</script>
